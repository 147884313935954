@use "sass:math";

/* Globals */
$defaultPrimaryColor: #242424;
$defaultBackgroundColor: #f7f5f4;

$inputBackgroundColor: #f6f4f2;
$defaultBorderColor: black;
$errorColor: #d7584b;
$successColor: #309f3c;

* {
	transition: ease-in-out 0.2s;
}

:root {
	--secondary-color: #474645;
}

/* Tags */
html {
	background: $defaultBackgroundColor;
	color: $defaultPrimaryColor;
}

main {
	font-family: Inter, -apple-system, BlinkMacSystemFont, "avenir next", avenir, helvetica, "helvetica neue", ubuntu, roboto, noto, "segoe ui", arial,
		sans-serif;
	letter-spacing: -0.0015em;
	line-height: 1.45;
	font-size: 0.875rem;
	color: $defaultPrimaryColor;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 2rem;
	padding-bottom: 1rem;
	text-align: center;
	height: 100%;
}

form {
	margin-left: auto;
	margin-right: auto;
	max-width: 20rem;

	input,
	button {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		font-size: 1rem;
		font-weight: 400;
		width: 100%;
		margin-top: 0.65rem;
		border-style: solid;
		border-radius: 0.25rem;
		border-width: 1px;
		box-sizing: border-box;
		padding: 0.7rem 0.75rem;
	}

	input {
		background-color: $inputBackgroundColor;
		border-color: rgba($defaultBorderColor, 0.05);
		font-size: 1rem;
	}

	input::placeholder {
		font-size: 1rem;
	}

	button {
		background-color: var(--secondary-color);
		border-color: rgba($defaultBorderColor, 0.05);
		color: white;
		font-weight: 500;
		font-size: 0.87rem;
		cursor: pointer;
		&:hover {
			background-color: white !important;
			color: var(--secondary-color);
			border-color: var(--secondary-color);
		}
	}
}

.custom-anchor {
	display: block;
	width: 115px;
	height: 25px;
	background: #4e9caf;
	padding: 10px;
	text-align: center;
	border-radius: 0.4rem;
	color: white;
	font-weight: bold;
	line-height: 25px;
	background-color: var(--secondary-color);
	margin: 0 auto;
	text-decoration: none;
}

.maintain-width {
	max-width: 20rem;
	width: 370px;
}

/* Component Classes */

.error {
	border: 1px solid $errorColor;
	background-color: rgba($errorColor, 0.1);
	color: $errorColor;
}

.success {
	border: 1px solid $successColor;
	background-color: rgba($successColor, 0.1);
	color: $successColor;
}

.toast {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	font-size: 1rem;
	font-weight: 400;
	width: 100%;
	border-radius: 0.25rem;
	box-sizing: border-box;
	font-size: 0.7rem;
	opacity: 0;
	height: 0px;
	padding: 0px;
	margin-top: 0px;
}

.toast-animate {
	opacity: 1 !important;
	height: 41px;
	padding: 0.7rem 0.75rem;
	margin-top: 0.5rem;
}

.error.toast-animate {
	padding: 0.7rem 0.5rem !important;
}

.logo-image {
	max-width: 70px;
	border-radius: 15px;
}

.vertical-divider {
	height: 60px;
	border-right: 1px solid rgba($defaultPrimaryColor, 0.5);
}

.vertically-centered-container {
	position: absolute;
	top: 32%;
}

.floating-container {
	background: white;
	padding: 2vh;
	border-radius: 1.4rem;
	box-shadow: 0 20px 25px -5px rgb(0 0 0 / 10%), 0 10px 10px -5px rgb(0 0 0 / 4%);
	min-height: 200px;
}

/* Fonts */
.sentence-case {
	text-transform: capitalize;
}

.font-weight-light {
	font-weight: 300;
}

/* Margin/Padding */
@each $abbr, $name in ("t": "top", "r": "right", "b": "bottom", "l": "left") {
	@each $prop, $prop-name in ("m": "margin", "p": "padding") {
		@for $i from 0 through 100 {
			.#{$prop}#{$abbr}-#{$i} {
				#{$prop-name}-#{$name}: 1vh * ($i);
			}
		}
	}
}

.d-ilb {
	display: inline-block;
}

.d-b {
	display: block;
}

.d-n {
	display: none;
}

/* Loading Spinner */
.loader {
	width: 30px;
	height: 30px;
	display: inline-block;
	position: relative;
	margin-top: 20px;
}
.loader::after,
.loader::before {
	content: "";
	box-sizing: border-box;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background: var(--secondary-color);
	position: absolute;
	left: 0;
	top: 0;
	animation: animloader 1s linear infinite;
}

@keyframes animloader {
	0% {
		transform: scale(0);
		opacity: 1;
	}
	100% {
		transform: scale(1);
		opacity: 0;
	}
}
